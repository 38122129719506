// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles/vars.css';
import './styles/main.css';
import './styles/nav.css';
import App from './App';
import Nav from './components/Nav';
import Footer from './components/Footer';
import SocialFooter from './components/Social-Footer';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import client from './apollo';
import Knjiski from './components/Knjiski';
import Razgovorni from './components/Razgovorni';
import ProduzenaRuka from './components/Produzena-ruka';
import Korpus from './components/Korpus';
import Omotivaciji from './components/Omotivaciji';
import Opokretu from './components/Opokretu';
import Oautorici from './components/Oautorici';
// import Pocetna from './components/Pocetna';
import O_projektu from './components/O-projektu';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';




ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ApolloProvider client={client}>
        <Nav />

        {/* Conditionally render App based on the route */}
        <Routes>
          <Route path="/o-projektu" element={<O_projektu />} />
          <Route path="/knjiski-idiomi" element={<Knjiski />} />
          <Route path="/razgovorni-idiomi" element={<Razgovorni />} />
          <Route path="/korpus" element={<Korpus />} />
          <Route path="/idiomi-u-pokretu" element={<Opokretu />} />
          <Route path="/o-motivaciji" element={<Omotivaciji />} />
          <Route path="/produzena-ruka" element={<ProduzenaRuka />} />
          <Route path="/o-autorici" element={<Oautorici />} />
          <Route path="/*" element={<App />} />
        </Routes>

        <SocialFooter />
        <Footer />
      </ApolloProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to measure performance in your app, you can uncomment the next line.
// Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
